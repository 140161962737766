<template>
    <el-form ref="form" class="content bascform" :model="form.data" :class="{disabled:formDisabled}"
        label-width="130px">
        <div class="col col4">
            <el-form-item label="收款账户名" prop="beneficiaryAccountName" :rules="{ required: true, message: '请选择收款账号', trigger: 'blur' }">
              <span class="onlyText">
                {{form.data.beneficiaryAccountName}}
              </span>
              <el-button style="margin-left:5px" v-if="!formDisabled" type="text" @click="selectAct">选择账户</el-button>
            </el-form-item>
        </div>
        <div class="col col4">
            <el-form-item label="付款账号" prop="payAccount" :rules="{ required: true, message: '请选择付款账号', trigger: 'blur' }">
              <el-select filterable v-model="form.data.payAccount" placeholder="请选择" :disabled="formDisabled" @change="account">
                      <el-option v-for="(item) in accountListData"
                      :key="item.bankAccount"
                      :label="item.bankAccount+'-'+item.openingBankName"
                      :value="item.bankAccount">
                      </el-option>
              </el-select>
            </el-form-item>
        </div>
        <div class="col col4">
            <el-form-item label="开户行名称">
              <span class="onlyText">
                {{form.data.openingBankName}}
              </span>
            </el-form-item>
        </div>
        <div class="col col4" style="vertical-align: top;">
            <el-form-item label="付款方式" prop="payWay" :rules="{ required: true, message: '请选择付款方式', trigger: 'blur' }">
                <el-select @change="payWayChange" v-model="form.data.payWay" :disabled="formDisabled">
                    <el-option label="银行存款" value="02"></el-option>
                    <el-option label="银承汇票" value="01"></el-option>
                    <el-option label="贷款支付" value="03"></el-option>
                </el-select>
            </el-form-item>
            <span class="hpInfo" v-if="form.data.payWay=='01'">
              <el-tooltip placement="right-start" effect="light">
                <template #content>
                  <div style="width:500px">
                    <global-table  class="content bascform" :tableField="tableFieldtb" :tableData="form.data.draftList" ></global-table>
                  </div>
                </template>
                <el-button size="medium" type="text" @click="payWayChange('01')" icon="el-icon-warning-outline"></el-button>
              </el-tooltip>
            </span>
        </div>
        <div class="col col4">
            <el-form-item label="是否募投">
              <el-radio-group v-model="form.data.equityInvestmentProject" @change="equityInvestmentProjectchange">
                <el-radio label="是" size="large">是</el-radio>
                <el-radio label="否" size="large">否</el-radio>
              </el-radio-group>
            </el-form-item>
        </div>
        <div class="col col4">
            <el-form-item label="收款账号">
              <span class="onlyText">
                {{form.data.bankAccount}}
              </span>
            </el-form-item>
        </div>
        <div class="col col4" v-if="draftBill==0">
            <el-form-item label="">
                {{''}}
            </el-form-item>
        </div>
        <div class="col col4" v-if="draftBill!=0">
            <el-form-item label="汇票总额">
              <span class="onlyText">
                {{draftBill}}
              </span>
            </el-form-item>
        </div>
        <div class="col col4">
            <el-form-item label="开户行所在省市">
              <span class="onlyText">
                {{form.data.openingBankAddressProvince+form.data.openingBankAddressCity}}
              </span>
            </el-form-item>
        </div>
        <dialog-box dialogWidth="800px" ref="dialogAct" :tableField="actTableField" :dialogShow="accountDialogShow"
        :tableData="accountTableData" @affirm='cancel' title="选择账号" componentName="GlobalTable" highlight-current-row
        @handleClose="cancel" @current-change="handleCurrentChange"
              :buttonData="[ { label: '取消', action: 'handleClose', size: 'mini' },{ label: '确认', action: 'affirm', size: 'mini', type: 'primary' }]">
        </dialog-box>
        <dialog-box dialogWidth="800px" ref="dialogbox" :tableField="tableField" :dialogShow="dialogShow"
        :tableData="tableData" @affirm='affirm' title="选择汇票" componentName="GlobalTable"
        @SelectionChange="SelectionChange" @handleClose="confirm"
              :buttonData="[ { label: '取消', action: 'handleClose', size: 'mini' },{ label: '确认', action: 'affirm', size: 'mini', type: 'primary' }]">
        </dialog-box>
    </el-form>
</template>

<script>
/* eslint-disable eqeqeq */
import DialogBox from '@/components/plug/DialogBox.vue'
import { tableField, tableFieldtb, actTableField } from './draft'
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
export default {
  components: { ...Component.components, DialogBox },
  name: 'AccountInformation',
  props: ['formDisabled', 'formData', 'draftBill'],
  data () {
    return {
      form: this.formData,
      dialogShow: false,
      tableField: tableField,
      actTableField: actTableField,
      accountDialogShow: false,
      tableData: [],
      selectData: [],
      accountListData: [],
      accountTableData: [],
      tableFieldtb: tableFieldtb
    }
  },
  created () {
    let suppliernum = this.form.data.drawerCode
    if (!suppliernum) {
      suppliernum = this.form.data.supplierCode
    }
    if (this.form.data.equityInvestmentProject != '是') {
      this.form.data.equityInvestmentProject = '否'
    }
    if (suppliernum) {
      this.accountChange(suppliernum)
      request(`/api/reconciliation/pay/getBankByPlan/${this.form.data.companyCode}`, 'post').then((res) => {
        if (res.code == 200) {
          this.accountListData = res.data
        }
      })
    }
  },
  watch: {
    'form.data': function () {
      let suppliernum = this.form.data.drawerCode
      if (!suppliernum) {
        suppliernum = this.form.data.supplierCode
      }
      if (suppliernum) {
        this.accountChange(suppliernum)
      }
      if (this.form.data.equityInvestmentProject != '是') {
        this.form.data.equityInvestmentProject = '否'
      }
      request(`/api/reconciliation/pay/getBankByPlan/${this.form.data.companyCode}`, 'post').then((res) => {
        if (res.code == '200') {
          this.accountListData = res.data
        }
      })
    }
  },
  methods: {
    account (data) {
      for (const item of this.accountListData) {
        if (item.bankAccount === data) {
          this.form.data.payBankName = `${item.openingBankName}`
        }
      }
    },
    payWayChange (val) {
      if (val === '01' && !this.formDisabled) {
        request(`/api/reconciliation/pay/getDraftList?companyCode=${this.form.data.companyCode}`, 'get').then((res) => {
          if (res.code == '200') {
            this.tableData = res.data
            this.dialogShow = true
          }
        })
      }
      if (val !== '01') {
        this.form.data.draftList = []
      }
      if (this.form.data.equityInvestmentProject == '是' && this.form.data.payWay == '03') {
        this.$message({
          message: '贷款付款不允许募投！',
          type: 'error'
        })
        this.form.data.equityInvestmentProject = '否'
      }
    },
    equityInvestmentProjectchange () {
      if (this.form.data.equityInvestmentProject == '是' && this.form.data.payWay == '03') {
        this.$message({
          message: '贷款付款不允许募投！',
          type: 'error'
        })
        this.form.data.equityInvestmentProject = '否'
      }
    },
    accountChange () {
      /* request(`/api/supplier/basicData/getBankByErpCode/${this.form.data.drawerCode}`, 'put').then((res) => {
        if (res.code === '200') {
          this.accountListData = res.data
        }
      }) */
    },
    SelectionChange (data) {
      this.selectData = data
    },
    selectAct () {
      let code = this.form.data.drawerCode
      if (!code) {
        code = this.form.data.supplierCode
      }
      request(`/api/supplier/basicData/getBankByErpCode/${code}`, 'put').then((res) => {
        if (res.code == '200') {
          this.accountTableData = res.data
          this.accountDialogShow = true
        }
      })
    },
    handleCurrentChange (data) {
      this.form.data.beneficiaryAccountName = data.bankAccountName
      this.form.data.openingBankName = data.openingBankName
      this.form.data.bankAccount = data.bankAccount
      this.form.data.openingBankAddress = data.province + '' + data.city
      this.form.data.openingBankAddressCity = data.city
      this.form.data.openingBankAddressProvince = data.province
      this.accountDialogShow = false
    },
    confirm () {
      this.dialogShow = false
    },
    cancel () {
      this.accountDialogShow = false
    },
    affirm () {
      if (this.selectData.length > 0) {
        this.form.data.draftList = this.selectData
        this.dialogShow = false
      } else {
        this.$message({
          message: '请勾选汇票！',
          type: 'error'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.hpInfo{
  float: right;
  margin-right: -25px;
  margin-top: -28px;
  *{
    font-size: 20px;
  }
  .el-button{
    padding: 0;
    min-height: 20px;
  }
}
</style>
