<template>
    <div class="mainform">
        <!-- 付款单详情 (应付)-->
        <div class="mainHeader">付款申请  <span @click="back" class="back">返回></span></div>
        <div class="form">
            <div class="header">付款内容 </div>
                <el-form  class="content bascform disabled" :model="form" :disabled="true" label-width="120px">
                    <div  class="col col4">
                        <el-form-item label="付款单号" >
                            <el-input v-model="form.payCode"></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="公司">
                          <span class="onlyText">{{form.companyCode+'-'+form.company}}</span>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="开票方">
                          <span class="onlyText">{{form.drawerCode+'-'+form.drawerName}}</span>
                        </el-form-item>
                    </div>
                     <div  class="col col4">
                        <el-form-item label="币种" >
                            <el-input v-model="form.currency"></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="申请总额" >
                          <span style="margin-left: 16px;">
                            {{amount(form.totalMoney)}}
                          </span>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="申请人" >
                            <el-input v-model="form.createUserName"></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="申请人部门">
                            <el-input v-model="form.dept"></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="申请时间">
                            <el-input v-model="form.createDate"></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="申请类型">
                          <span class="onlyText">{{form.type==1?'应付':form.type==2?'预付':''}}</span>
                        </el-form-item>
                    </div>
                    <div class="col col4">
                        <el-form-item label="状态">
                          <span class="onlyText">
                            {{form.status === 1 ? '审批中' : form.status === 2 ? '驳回' : form.status === 3 ? '审批通过' : form.status === 4 ? '出纳已处理'
                              : form.status === 5 ? '出纳已驳回' : form.status === 6 ? '付款未过账' : form.status === 7 ? '付款已完成' : form.status === 8 ? '付款已冲销'
                              : form.status}}
                          </span>
                        </el-form-item>
                    </div>
                    <div class="col col8">
                        <el-form-item label="备注及原因">
                            <el-input type="textarea" v-model="form.bz"></el-input>
                        </el-form-item>
                    </div>
                </el-form>
            <div class="header" v-if="detailSHow">账号信息 </div>
                <account-information ref="accountForm" v-if="detailSHow" :draftBill="draftBill" :formDisabled="formDisabled" :formData="formData"></account-information>
            <div class="header">付款明细</div>
                <global-table  class="bascform" :tableField="tableField" :tableData="tableData" ></global-table>
            <approval-mind v-if="$store.state.loginInfo.userType!='1'"></approval-mind>
             <div class="action">
                <el-button v-if="haveButton" @click="reject" type="primary" size="medium" >驳回</el-button>
                <el-button v-if="haveButton" @click="submit" type="primary" size="medium" >通过</el-button>
                <el-button v-if="printBt" @click="print" type="primary" size="medium" >打印</el-button>
            </div>
        </div>
    </div>
</template>

<script>

import Component from '@/assets/js/components.js'
import { tableField, tableData } from './js/paymentFindDetail'
import { request, exportForm, requestForm } from '@/assets/js/http.js'
import AccountInformation from './module/AccountInformation.vue'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
export default {
  components: { ...Component.components, AccountInformation },
  name: 'PaymentFindDetail',
  data () {
    FlowCtlData.docid = ''
    return {
      tableField: tableField,
      tableData: tableData,
      form: {},
      formData: { data: {} },
      formDisabled: true,
      haveButton: false,
      draftBill: 0,
      detailSHow: false,
      printBt: false
    }
  },
  created () {
    const id = this.$route.query.id
    if (id) {
      request('/api/reconciliation/pay/getByPayOrderId/' + id, 'get').then((res) => {
        if (res.code === '200') {
          this.form = res.data
          this.formData.data = this.form
          this.tableData = res.data.accountsPayableDetailList
        }
        FlowCtlData.flowid = 'PaymentApproval'
        FlowCtlData.docid = id
        FlowCtlData.initialize().then(() => {
          if (FlowCtlData.nodeThisData[0].APPROVER.split(';').includes(FlowCtlData.loginID)) {
            this.haveButton = true
            if (FlowCtlData.nodeThisData[0].NODEID === 'cxjy3jdEEJ8AEZSn2EmJJet2h74ZJjfW') {
              this.formDisabled = false
            }
          }
          if (FlowCtlData.nodeThisData[0].NODEID === 'cxjy3jdEEJ8AEZSn2EmJJet2h74ZJjfW' && FlowCtlData.nodeThisData[0].APPROVER.split(';').includes(FlowCtlData.loginID)) {
            this.detailSHow = true
          }
          if (FlowCtlData.nodeThisData[0].NODEID === 'flowEnd') {
            this.detailSHow = true
            this.printBt = true
          }
        })
        FlowCtlData.getApprPsn('551625315191681024').then((val) => { this.formData.data.buyerLeader = val })
        FlowCtlData.getApprPsn('561052621739134976').then((val) => { this.formData.data.accountant = val })
        FlowCtlData.getApprPsn('902784973681004544').then((val) => { this.formData.data.legalspecialist = val })
      })
    }
  },
  watch: {
    $route: {
      handler () {
        const id = this.$route.query.id
        if (id) {
          request('/api/reconciliation/pay/getByPayOrderId/' + id, 'get').then((res) => {
            if (res.code === '200') {
              this.form = res.data
              this.formData.data = this.form
              this.tableData = res.data.accountsPayableDetailList
            }
            FlowCtlData.flowid = 'PaymentApproval'
            FlowCtlData.docid = id
            FlowCtlData.initialize().then(() => {
              if (FlowCtlData.nodeThisData[0].APPROVER.split(';').includes(FlowCtlData.loginID)) {
                this.haveButton = true
                if (FlowCtlData.nodeThisData[0].NODEID === 'cxjy3jdEEJ8AEZSn2EmJJet2h74ZJjfW') {
                  this.formDisabled = false
                }
              }
              if (FlowCtlData.nodeThisData[0].NODEID === 'cxjy3jdEEJ8AEZSn2EmJJet2h74ZJjfW' && FlowCtlData.nodeThisData[0].APPROVER.split(';').includes(FlowCtlData.loginID)) {
                this.detailSHow = true
              }
              if (FlowCtlData.nodeThisData[0].NODEID === 'flowEnd') {
                this.detailSHow = true
                this.printBt = true
              }
            })
            FlowCtlData.getApprPsn('551625315191681024').then((val) => { this.formData.data.buyerLeader = val })
            FlowCtlData.getApprPsn('561052621739134976').then((val) => { this.formData.data.accountant = val })
            FlowCtlData.getApprPsn('902784973681004544').then((val) => { this.formData.data.legalspecialist = val })
          })
        }
      }
    },
    'formData.data.draftList': function () {
      this.draftBill = 0
      for (const item of this.formData.data.draftList) {
        this.draftBill = this.draftBill + parseFloat(item.wrbtr)
      }
      this.draftBill = this.draftBill.toFixed(2)
    }
  },
  methods: {
    amount (data) {
      data = parseFloat(data) ? parseFloat(data).toLocaleString('zh', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : data
      return data
    },
    back: () => {
      window.history.back()
    },
    print () {
      exportForm('/api/reconciliation/pay/print/' + this.$route.query.id, 'get').then((res) => {
        const url = window.URL.createObjectURL(res.data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        eleLink.click()
        window.URL.revokeObjectURL(url)
      })
    },
    submit () {
      if (this.detailSHow) {
        let d = false
        this.$refs.accountForm.$refs.form.validate((val) => {
          if (!val) {
            d = true
            FlowCtlData.formAlert(this, 'error', '请将付款信息填写完整！')
            return false
          }
        })
        if (d) {
          return false
        }
      }
      if (this.formData.data.payWay === '01') {
        if (parseFloat(this.form.totalMoney).toFixed(2) !== parseFloat(this.draftBill).toFixed(2)) {
          this.$message({
            message: '汇票总额必须与申请总额相等！',
            type: 'error'
          })
          return false
        }
      }
      const lcobj = {}
      lcobj.buyerLeader = this.formData.data.buyerLeader
      lcobj.accountant = this.formData.data.accountant
      lcobj.legalspecialist = this.formData.data.legalspecialist
      lcobj.equityInvestmentProject = this.formData.data.equityInvestmentProject
      const tempspr = this.formData.data.accountant
      FlowCtlData.getApprPsn('', `${this.formData.data.companyCode}-公司【付款审批】`).then((val) => {
        this.formData.data.accountant = val
        lcobj.accountant = val
        if (this.formData.data.accountant === '') {
          this.formData.data.accountant = tempspr
          lcobj.accountant = tempspr
        }
        FlowCtlData.getNodeAction('TJ', lcobj).then((val) => {
          if (val) {
            // FlowCtlData.alertDialog(this).then(() => {
            FlowCtlData.alertBHDialog(this, null, `下一环节：${FlowCtlData.nextflowactionmsg.nodeMsg.nodeName}。是否提交？`).then(({ value }) => {
              FlowCtlData.setFormData(FlowCtlData, lcobj)
              this.formData.data.status = 1
              if (FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeId === 'flowEnd') {
                this.formData.data.status = 3
              }
              request('/api/reconciliation/pay/updateOrder', 'put', this.formData.data, 'application/json').then((Response) => {
                if (Response.code === '200') {
                  if (FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeId === 'flowEnd') {
                    request(`/api/reconciliation/pay/payCallSap/${this.$route.query.id}`, 'post').then((response) => {
                      if (response.code === '200') {
                        FlowCtlData.setNodeAction(value).then((val) => {
                          FlowCtlData.formAlert(this, 'success')
                          this.$router.push('/FinancialManagement/PaymentFind')
                        })
                      }
                    })
                  } else {
                    FlowCtlData.setNodeAction(value).then((val) => {
                      FlowCtlData.formAlert(this, 'success')
                      this.$router.push('/FinancialManagement/PaymentFind')
                    })
                  }
                }
              })
            }).catch(() => { FlowCtlData.formAlert(this, 'info') })
          }
        }).catch(() => { FlowCtlData.formAlert(this, 'error') })
      })
    },
    reject () {
      FlowCtlData.getNodeAction('BH', this.formData.data).then(
        (val) => {
          if (val) {
            FlowCtlData.alertBHDialog(this).then(({ value }) => {
              FlowCtlData.setFormData(FlowCtlData, this.formData.data)
              this.formData.data.status = 2
              request('/api/reconciliation/pay/updateOrder', 'put', this.formData.data, 'application/json').then((Response) => {
                if (Response.code === '200') {
                  requestForm(`/api/reconciliation/pay/rejectOrder/${this.$route.query.id}`, 'put').then((res) => {
                    if (res.code === '200') {
                      FlowCtlData.setNodeAction(value).then((val) => {
                        if (val) {
                          this.$message({
                            message: '驳回成功',
                            type: 'success'
                          })
                        } else {
                          this.$message({
                            message: '操作失败',
                            type: 'error'
                          })
                        }
                      })
                    }
                  })
                }
              })
            })
          }
        }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消提交'
        })
      })
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elForm.scss';
</style>
