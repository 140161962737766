export var tableField = [
  { selectType: 'selection', width: '', type: 'input' },
  { label: '汇票流水号', code: 'zhpno', type: 'input', width: '' },
  { label: '票号', code: 'zhpnr', type: 'input', width: '' },
  { label: '金额', code: 'wrbtr', type: 'input', width: '' },
  { label: '公司', code: 'bukrs', type: 'input', width: '' }
]
export var tableFieldtb = [
  { label: '汇票流水号', code: 'zhpno', type: 'input', width: '' },
  { label: '票号', code: 'zhpnr', type: 'input', width: '' },
  { label: '金额', code: 'wrbtr', type: 'input', width: '' },
  { label: '公司', code: 'bukrs', type: 'input', width: '' }
]

export var actTableField = [
  { label: '账户名', code: 'bankAccountName', type: 'input', width: '' },
  { label: '开户行名称', code: 'openingBankName', type: 'input', width: '' },
  { label: '银行账号', code: 'bankAccount', type: 'input', width: '' },
  {
    label: '开户行所在省市',
    type: 'function',
    width: '',
    handle (index, data) {
      return data.province + '' + data.city
    }
  }
]
