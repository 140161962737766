export var tableField = [
  { label: '预付标识(Y/N)', code: 'prepayIdentify', type: 'input', width: '120' },
  { label: '公司名称', code: 'companyName', type: 'input', width: '' },
  { label: '供应商', code: 'supplierName', type: 'input', width: '' },
  { label: '网上发票号 ', code: 'onlineInvoiceNumber', type: 'input', width: '200' },
  { label: '行号 ', code: 'onlineInvoiceItemNumber', type: 'input', width: '110' },
  { label: '采购单编号', code: 'purchaseOrderCode', type: 'input', width: '120' },
  { label: '采购单行号', code: 'purchaseOrderItemCode', type: 'input', width: '100' },
  { label: '物料编号', code: 'materielCode', type: 'input', width: '' },
  { label: '物料描述', code: 'materialDescribe', type: 'input', width: '' },
  { label: '开票金额', code: 'invoiceAmount', type: 'amount', align: 'right', width: '' },
  { label: '本次申请金额', code: 'applicationAmount', type: 'amount', align: 'right', width: '110' },
  { label: '已申请金额', code: 'appliedAmount', type: 'amount', align: 'right', width: '100' },
  { label: '预付冲销', code: 'prepaidSterilisation', type: 'input', width: '' },
  { label: '预付合计', code: 'prepayTotal', type: 'amount', align: 'right', width: '' },
  { label: '调整金额', code: 'adjustmentAmount', type: 'amount', align: 'right', width: '' },
  { label: '剩余可申请金额', code: 'remainingApplicableAmount', type: 'amount', align: 'right', width: '120' },
  { label: '订单单价', code: 'orderUnitPrice', type: 'amount', align: 'right', width: '' },
  { label: '开票单价', code: 'invoiceUnitPrice', type: 'amount', align: 'right', width: '' },
  { label: '开票数量', code: 'invoiceNumber', type: 'input', width: '' },
  { label: '税率', code: 'taxRate', type: 'input', width: '' }

]

export var tableData = []
